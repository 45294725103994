<template>
  <el-select
    filterable
    v-model="sriFormsPaymentsSelect"
    :placeholder="placeholder"
    @change="onChangeSelect"
    :disabled="disabled"
  >
    <el-option
      v-for="p in formsPayments"
      :key="p.id"
      :label="p.title"
      :value="p.id"
    ></el-option>
  </el-select>
</template>

<script>
import SriServices from "@/services/SriServices";

export default {
  name: "SelectSriFormsPaymentsComponent",
  data() {
    return {
      formsPayments: [],
      sriFormsPaymentsSelect: this.setSriFormsPaymentId
    };
  },
  computed: {},
  methods: {
    onChangeSelect() {
      this.$emit("onChangeSelect", {
        sriFormsPaymentsSelect: this.sriFormsPaymentsSelect
      });
    },
    async getFormsPayments() {
      await SriServices.getFormsPayments().then(response => {
        const { data, success } = response.data;
        if (success) this.formsPayments = data;
      });
    }
  },
  props: {
    setSriFormsPaymentId: {
      default: null
    },
    placeholder: {
      default: "Seleccionar"
    },
    disabled: {
      default: false
    }
  },
  mounted() {
    this.getFormsPayments();
  }
};
</script>

<style lang="sass" scoped></style>
