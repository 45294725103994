<template>
  <div>
    <slot name="label">
      <div class="d-flex justify-content-between align-items-center">
        <label class="font-semi-bold m-0 d-block">{{ title }}</label>
        <LinkPlusComponent
          onlyIcon
          @onClick="onAddBusiness"
          v-if="isAddBusiness"
        />
      </div>
    </slot>

    <el-select
      filterable
      remote
      v-model="userBusinessClientId"
      :placeholder="placeholder"
      @change="onChangeSelect"
      :remote-method="getBusiness"
      :disabled="disabled"
      :loading="loading"
      :trigger-on-focus="false"
    >
      <el-option
        v-for="client in clients"
        :key="client.id"
        :label="client.name"
        :value="client.id"
      ></el-option>
    </el-select>

    <el-dialog
      :visible.sync="dialogBusinessAdd"
      :fullscreen="true"
      :before-close="handleCloseBusinessAdd"
    >
      <BusinessAddComponent
        :showBackIcon="false"
        v-if="dialogBusinessAdd"
        @onSuccess="onSuccessBusinessAdd"
      />
    </el-dialog>
  </div>
</template>

<script>
import businessService from "../business/services/businessService";
import BusinessAddComponent from "../business/components/Add";
import LinkPlusComponent from "./LinkPlus";

export default {
  name: "SelectBusinessComponent",
  data() {
    return {
      clients: [],
      contacts: [],
      userBusinessClientId: null,
      dialogBusinessAdd: false,
      loading: false
    };
  },
  computed: {
    findBusiness() {
      return this.clients.find(
        client => client.id === this.userBusinessClientId
      );
    }
  },
  methods: {
    goRouter(name, params = {}) {
      this.$router.push({ name, params });
    },
    onAddBusiness() {
      this.dialogBusinessAdd = true;
      this.userBusinessClientId = null;
    },
    onChangeSelect() {
      if (this.userBusinessClientId) {
        this.clients.map(client => {
          if (client.id === this.userBusinessClientId) {
            this.contacts = client.contacts;
          }
        });

        this.$emit("onChangeSelect", {
          client: this.findBusiness,
          contacts: this.contacts
        });
      }
    },
    onSuccessBusinessAdd({ client }) {
      this.clients = [client, ...this.clients];
    },
    handleCloseBusinessAdd() {
      this.dialogBusinessAdd = false;
    },
    async getBusiness(query) {
      this.loading = true;
      await businessService
        .getBusiness({
          params: {
            name: query
          }
        })
        .then(response => {
          if (response.data.clients) {
            this.clients = response.data.clients;

            if (this.onlyProviders) {
              this.clients = response.data.clients.filter(client => {
                return client.contact_type == 2;
              });
            }
          }
        })
        .finally(() => (this.loading = false));
    }
  },
  components: {
    BusinessAddComponent,
    LinkPlusComponent
  },
  props: {
    title: {
      default: "Empresa"
    },
    placeholder: {
      default: "Buscar empresa"
    },
    onlyProviders: {
      defalut: false
    },
    setUserBusinessClientId: {
      default: null
    },
    disabled: {
      default: false
    },
    isAddBusiness: {
      default: true,
      type: Boolean
    }
  },
  mounted() {
    if (this.setUserBusinessClientId != null) {
      this.getBusiness();
      this.userBusinessClientId = this.setUserBusinessClientId;
    }
  }
};
</script>

<style lang="sass" scoped></style>
