<template>
  <section class="document-additional-info">
    <h5 class="h5 font-bold">Información adicional</h5>
    <b-row v-for="(adittional, index) in additionals" :key="index" class="my-3">
      <b-col xs="12" sm="5" md="5">
        <el-input
          type="text"
          placeholder="Nombre"
          class=""
          v-model="adittional.name"
          @blur="onInputBlur"
        ></el-input>
      </b-col>
      <b-col xs="11" sm="5" md="5">
        <el-input
          type="text"
          placeholder="Descripción"
          class=""
          v-model="adittional.description"
          @blur="onInputBlur"
        ></el-input>
      </b-col>
      <b-col xs="1" sm="1" md="1">
        <ButtonIcoComponent
          v-if="additionals.length > 1"
          class="float-right"
          @onClick="removeAdditional(index)"
        />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import ButtonIcoComponent from "../../components/ButtonIco";

export default {
  name: "DocumentAdditionalInfoComponent",
  data() {
    return {
      additionals: this.additionalExtras
    };
  },
  watch: {
    additionalExtras(value) {
      this.additionals = value;
    }
  },
  methods: {
    onInputBlur() {
      const lastAdditionalInfo = this.additionals[this.additionals.length - 1];
      if (lastAdditionalInfo.name && lastAdditionalInfo.description) {
        this.additionals = [
          ...this.additionals,
          ...[
            {
              name: null,
              description: null
            }
          ]
        ];
      }

      this.onToogleAdditionals();
    },
    removeAdditional(index) {
      this.additionals.splice(index, 1);
      this.onToogleAdditionals();
    },
    onToogleAdditionals() {
      this.$emit("onAdditionals", { additionals: this.additionals });
    }
  },
  props: {
    additionalExtras: {
      type: Array,
      default: () => []
    }
  },
  components: {
    ButtonIcoComponent
  },
  mounted() {
    if (!this.additionalExtras.length) {
      this.additionals = [
        {
          name: null,
          description: null
        }
      ];
    }
  }
};
</script>

<style></style>
