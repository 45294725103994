<template>
  <div class="" v-if="invoice">
    <el-form :model="formDocument" :rules="rules" ref="formDocument">
      <TopBarTitleComponent title="Editar Factura" :showBackIcon="true">
        <template slot="right-area">
          <div class="d-flex justify-content-end align-items-center">
            <ButtonPrimaryComponent
              title="Guardar"
              @onSaveClick="handleSaveClick"
            />
          </div>
        </template>
      </TopBarTitleComponent>
      <b-container fluid class="pb-4 px-lg-4 pt-4">
        <b-row>
          <b-col>
            <div class="card box-shadow" v-if="apiResponse.success">
              <div class="card-body">
                <b-row>
                  <b-col xs="12" sm="12" md="12">
                    <b-row>
                      <b-col xs="12" sm="12" md="4">
                        <el-form-item prop="user_business_client_id">
                          <SelectBusinessComponent
                            @onChangeSelect="onChangeSelectBusiness"
                            :setUserBusinessClientId="
                              formDocument.user_business_client_id
                            "
                          />
                        </el-form-item>
                      </b-col>
                      <b-col xs="12" sm="12" md="4">
                        <el-form-item prop="email">
                          <slot name="label">
                            <label class="font-semi-bold m-0">Correo</label>
                          </slot>
                          <el-input
                            type="text"
                            placeholder=""
                            class=""
                            v-model="formDocument.email"
                          ></el-input>
                        </el-form-item>
                      </b-col>
                      <b-col xs="12" sm="12" md="4">
                        <el-form-item prop="user_business_e_point_id">
                          <SelectEmissionPointsComponent
                            @onChangeSelect="onChangeSelectEmissionPoints"
                            :setEmissionPointId="
                              formDocument.user_business_e_point_id
                            "
                            :disabled="hasProducts"
                          />
                        </el-form-item>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col xs="12" sm="12" md="4">
                        <InputDatePickerComponent
                          @onChangeDatePicker="onChangeDatePicker"
                          prop="emission_date"
                          placeholder=""
                          title="Fecha de emisión"
                          :setDate="formDocument.emission_date"
                          :isDisabledDate="true"
                        />
                      </b-col>
                      <b-col xs="12" sm="12" md="4">
                        <el-form-item prop="sri_forms_payment_id">
                          <slot name="label">
                            <label class="font-semi-bold m-0"
                              >Formas de pago</label
                            >
                          </slot>
                          <SelectSriFormsPaymentsComponent
                            :setSriFormsPaymentId="
                              formDocument.sri_forms_payment_id
                            "
                            @onChangeSelect="onChangeSriFormsPaymentsSelect"
                          />
                        </el-form-item>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <TableInputProducts
                  @onToogleProducts="onToogleProducts"
                  :setProducts="formDocument.products"
                  :setEmissionPointId="formDocument.user_business_e_point_id"
                  validate-stock
                  validate-emission-point
                />
                <section class="aditional-documents mt-4">
                  <b-row>
                    <b-col xs="12" sm="12" md="4">
                      <DocumentAdditionalInfoComponent
                        :additionalExtras="formDocument.additional_extras"
                        @onAdditionals="onAdditionals"
                      />
                    </b-col>
                    <b-col xs="12" sm="12" md="4" offset-md="4">
                      <DocumentCalculator
                        :setProducts="formDocument.products"
                        :setDisc="formDocument.discount"
                        @onChangeSwitchDiscount="onChangeSwitchDiscount"
                      />
                    </b-col>
                  </b-row>
                </section>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </el-form>
  </div>
</template>

<script>
import TopBarTitleComponent from "../../../../components/TopBarTitle";
import invoiceService from "../services/invoiceService";
import ButtonPrimaryComponent from "../../../../components/ButtonPrimary";
import SelectBusinessComponent from "../../../../components/SelectBusiness";
import SelectEmissionPointsComponent from "../../../../components/SelectEmissionPoints";
import InputDatePickerComponent from "../../../../components/InputDatePicker";
import TableInputProducts from "../../../components/TableInputProducts";
import SelectSriFormsPaymentsComponent from "../../../../components/SelectSriFormsPayments";
import DocumentAdditionalInfoComponent from "../../../components/DocumentAdditionalInfo";
import DocumentCalculator from "../../../components/DocumentCalculator";

import Validate from "@/assets/validate";

export default {
  name: "InvoiceViewPage",
  data: () => ({
    apiResponse: {
      success: false,
      message: null
    },
    invoice: null,
    formDocument: {
      user_business_client_id: "",
      emission_date: "",
      user_business_e_point_id: "",
      sri_forms_payment_id: "",
      discount: 0,
      discount_type: "",
      email: "",
      user_business_id: "",
      products: [],
      additional_extras: []
    },
    rules: {
      user_business_client_id: [Validate.rules.required("change")],
      user_business_e_point_id: [Validate.rules.required("change")],
      sri_forms_payment_id: [Validate.rules.required("change")],
      emission_date: [Validate.rules.required("change")],
      email: [Validate.rules.required()]
    }
  }),
  computed: {
    hasProducts() {
      return this.formDocument.products.length > 0;
    }
  },
  methods: {
    toggleApiResponse(property, value) {
      this.apiResponse[property] = value;
    },
    goPage(name) {
      this.$router.push({ name });
    },
    async getInvoice(invoiceId) {
      this.$store.dispatch("toggleRequestLoading");
      try {
        let response = await invoiceService
          .getInvoceId(invoiceId)
          .finally(() => {
            this.$store.dispatch("toggleRequestLoading");
          });
        if (response) {
          this.invoice = response.data.data;
          this.handleDataForm();
          this.toggleApiResponse("success", true);
        }
      } catch (error) {
        return false;
      }
    },
    handleSaveClick() {
      this.$refs.formDocument.validate(async valid => {
        if (valid) {
          this.$store.dispatch("toggleRequestLoading");
          try {
            let response = await invoiceService
              .updateInvoiceId(this.invoice.id, this.formDocument)
              .finally(() => {
                this.$store.dispatch("toggleRequestLoading");
              });
            const { success, message } = response.data;
            if (success) {
              this.$notifications.success({
                message
              });
            }
          } catch (error) {
            return false;
          }
        }
      });
    },
    handleDataForm() {
      const {
        user_business_client_id,
        user_business_id,
        products,
        user_business_e_point_id,
        sri_forms_payment_id,
        emission_date,
        email,
        discount,
        discount_type,
        additional_extras
      } = this.invoice;

      this.formDocument = {
        ...this.formDocument,
        ...{
          user_business_client_id,
          user_business_id,
          products,
          user_business_e_point_id,
          sri_forms_payment_id,
          emission_date,
          email,
          discount,
          discount_type,
          additional_extras
        }
      };
    },
    onChangeSelectBusiness({ client }) {
      const { email, id } = client;
      this.formDocument.email = email;
      this.formDocument.user_business_client_id = id;
    },
    onChangeSelectEmissionPoints({ emissionPointId }) {
      this.formDocument.user_business_e_point_id = emissionPointId;
    },
    onChangeDatePicker({ date }) {
      this.formDocument.emission_date = date;
    },
    onToogleProducts({ products }) {
      this.formDocument.products = [...products];
    },
    onChangeSwitchDiscount({ discount, type }) {
      this.formDocument.discount = discount;
      this.formDocument.discount_type = type;
    },
    onChangeSriFormsPaymentsSelect({ sriFormsPaymentsSelect }) {
      this.formDocument.sri_forms_payment_id = sriFormsPaymentsSelect;
    },
    onAdditionals({ additionals }) {
      this.formDocument.additional_extras = additionals;
    }
  },
  components: {
    TopBarTitleComponent,
    ButtonPrimaryComponent,
    SelectBusinessComponent,
    SelectEmissionPointsComponent,
    InputDatePickerComponent,
    TableInputProducts,
    SelectSriFormsPaymentsComponent,
    DocumentAdditionalInfoComponent,
    DocumentCalculator
  },
  mounted() {
    const { id: invoiceId } = this.$route.params;
    if (invoiceId) this.getInvoice(invoiceId);
  }
};
</script>

<style lang="scss" scoped>
.card-quotes-view {
  .card-quotes-view-header {
    .card-quotes-view-logo {
      flex-direction: column;
      height: 100%;
      p {
        margin: 0;
      }
    }
  }
  .card-quotes-view-body-products {
    min-height: 300px;
  }
}
</style>
